import { useEffect, useRef, useState } from 'react';

import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { login } from '../api/services/Auth';
import DynamicIcon from '../components/DynamicIcon';
import Logo from '../components/Logo.jsx';
import useAuth from '../hooks/useAuth';

export default function LoginScreen() {
  const { setAuth, setUser } = useAuth();
  const navigate = useNavigate();

  const emailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrorMsg('');
  }, [email, password]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await login(email, password);

      const user = response?.data;

      if (user.role === 'superadmin') {
        console.log(user);
        setAuth({ token: user.token });
        setUser(user);

        setEmail('');
        setPassword('');

        const to = `/control-panel/users`;
        navigate(to, { replace: true });
      } else {
        toast.error('You are not authorized to access this page');
      }
    } catch (err) {
      console.log(err.response);
      if (!err?.response || err.response?.status === 400) {
        setErrorMsg('Invalid Credentials');
      } else {
        setErrorMsg('Something went wrong, login Failed...');
      }
      errRef.current.focus();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <Logo />
            <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
              Sign in to <span className="text-blue">master admin</span> account
            </h2>
          </div>
          <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
            {errorMsg}
          </p>
          <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={handleLogin}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  ref={emailRef}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                  className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div className="relative block">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={!showPassword ? 'password' : 'text'}
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  required
                  className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
                  placeholder="Password"
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 text-gray-500 cursor-pointer"
                  onClick={handleClickShowPassword}>
                  {showPassword ? <DynamicIcon icon="EyeIcon" /> : <DynamicIcon icon="EyeSlashIcon" />}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between"></div>

            <div>
              <button
                type="submit"
                className="relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md group bg-blue hover:bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="w-5 h-5 text-blue group-hover:text-blue" aria-hidden="true" />
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
