import dayjs from 'dayjs';

import timeZone from './guessTimezone';

const calculateDifference = (pickupTime, realPickupTime) => {
  const pickup = dayjs(pickupTime).tz(timeZone);
  const realPickup = dayjs(realPickupTime).tz(timeZone);
  const difference = realPickup.diff(pickup, 'minute');
  if (difference > 0) {
    return <p className="text-red-500">(+{difference} min)</p>;
  } else if (difference < 0) {
    return <p className="text-green-500">({difference} min)</p>;
  } else {
    return <p className="text-gray-500">-</p>;
  }
};

export default calculateDifference;
