import useAuth from './useAuth';
import { logout as logoutRequest } from '../api/services/Auth';

const useLogout = () => {
  const { setAuth, setUser } = useAuth();

  const logout = async () => {
    setAuth({});
    setUser({});
    try {
      await logoutRequest();
    } catch (error) {
      console.error(error);
    }
  };
  return logout;
};

export default useLogout;
