import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { getAllDeliveries } from '../api/services/Deliveries';
import { DataTable } from '../components/DataTable/DataTable';
import { columns } from '../components/Deliveries/Table/columns';
import DateSelectorFilter from '../components/Filters/DateSelectorFilter';
import PeriodFilter from '../components/Filters/PeriodFilter';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const Deliveries = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [deliveriesLoading, setDeliveriesLoading] = useState(false);
  const [deliveryError, setDeliveryError] = useState('');
  const [cancelledDeliveries, setCancelledDeliveries] = useState([]);
  const [completedDeliveries, setCompletedDeliveries] = useState([]);
  const [deliveriesInProgress, setDeliveriesInProgress] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [period, setPeriod] = useState('Today');

  const axiosPrivate = useAxiosPrivate();

  const deliveriesUrl = getAllDeliveries();

  useEffect(() => {
    setDeliveryError('');
    getDeliveries();
  }, []);

  useEffect(() => {
    if (period === 'Today') {
      setStartDate('');
      setEndDate('');
      getDeliveries();
    }
  }, [period]);

  const getDeliveries = async () => {
    setDeliveriesLoading(true);

    let from = null;
    let to = null;

    if (period === 'Today') {
      from = dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
      to = dayjs().endOf('day').format('YYYY-MM-DD 23:59:59');
    } else {
      from = startDate && dayjs(startDate).format('YYYY-MM-DD HH:mm:ss');
      to = endDate && dayjs(endDate).format('YYYY-MM-DD 23:59:59');
    }

    try {
      const response = await axiosPrivate.get(deliveriesUrl, { params: { startDate: from, endDate: to } });

      console.log(response.data);
      if (response.data.length === 0) {
        setDeliveryError('No deliveries have been created');
        setDeliveriesLoading(false);
      } else {
        setDeliveries(response.data);
        setCompletedDeliveries(response.data.filter((delivery) => delivery.status === 3));
        setCancelledDeliveries(response.data.filter((delivery) => delivery.status === -1));
        setDeliveriesInProgress(response.data.filter((delivery) => delivery.status !== 3 && delivery.status !== -1));
        setDeliveryError('');
        setDeliveriesLoading(false);
      }
    } catch (error) {
      console.log(error);
      setDeliveryError('Something went wrong');
      setDeliveriesLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full m-auto">
        <div className="flex flex-col">
          <div className="text-left">
            {/* <Heading text="Delivery Stats" /> */}
            <div className="flex flex-col mt-2 mb-2 space-y-2 md:space-y-0 md:flex-row md:justify-between">
              <PeriodFilter period={period} setPeriod={setPeriod} />
              {period !== 'Today' && (
                <DateSelectorFilter
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  onClick={getDeliveries}
                  withSearch={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <p className="font-medium text-green-600">Completed: {completedDeliveries?.length}</p>
          <p className="font-medium text-red-500">Cancelled: {cancelledDeliveries?.length} </p>
          <p className="font-medium text-blue">In Progress: {deliveriesInProgress?.length} </p>
        </div>
        <div className="flex flex-col mt-2">
          <DataTable columns={columns} entityName="Deliveries" searchBy="order ID" filterValue="orderReference" data={deliveries} loading={deliveriesLoading} />
        </div>
      </div>
    </>
  );
};

export default Deliveries;
