import DatePicker from 'react-datepicker';

import Button from '../Button';
import 'react-datepicker/dist/react-datepicker.css';

const DateSelectorFilter = ({ startDate, endDate, setStartDate, setEndDate, onClick, withLabels, withSearch }) => {
  return (
    <div className="">
      <div className="flex flex-col items-baseline space-y-2 sm:items-center sm:flex-row sm:space-y-0 sm:space-x-8">
        <div className="flex items-center space-x-2">
          {withLabels && <h2 className="mr-2">From:</h2>}
          <DatePicker
            wrapperClassName="datePicker"
            className="calendar"
            required
            placeholderText="From:"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            dateFormat="MMMM d, yyyy"
          />
        </div>
        <div className="flex items-center space-x-2">
          {withLabels && <h2 className="mr-2">To:</h2>}
          <DatePicker
            wrapperClassName="datePicker"
            className="calendar"
            required
            placeholderText="To:"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="MMMM d, yyyy"
          />
        </div>
        {withSearch && (
          <div>
            <Button disabled={!startDate || !endDate} text="Search" onClick={onClick} bg="bg-blue" border="border-blue" textColor="text-white" rounded small />
          </div>
        )}
      </div>
    </div>
  );
};

DateSelectorFilter.defaultProps = {
  withLabels: false,
  withSearch: true
};

export default DateSelectorFilter;
