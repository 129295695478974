import { useEffect, useState } from 'react';

import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { updateBusiness } from '../../api/services/Business';
import { updateUser } from '../../api/services/User';
import Loader from '../../components/Loader';
import UserDetailModal from '../../components/UserDetailModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { DataTableColumnHeader } from '../DataTable/DataTableColumnHeader';
import { DataTablePagination } from '../DataTable/DataTablePagination';
import FormCheckbox from '../FormElements/FormCheckbox';
import Heading from '../Heading';
import { Input } from '../ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

const UserTable = ({ users, getUsers, loading, error }) => {
  const axiosPrivate = useAxiosPrivate();

  const [infoModal, setInfoModal] = useState(false);
  const [user, setUser] = useState({});
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);

  const columns = [
    {
      accessorKey: 'firstName',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
      cell: ({ row }) => {
        const user = row.original;
        return (
          <p
            onClick={() => {
              toggleInfoModal(), setUser(user);
            }}
            className="font-bold whitespace-nowrap text-blue hover:opacity-75 hover:cursor-pointer">
            {user.firstName} {user.lastName}
          </p>
        );
      },
      enableSorting: false
    },
    {
      accessorKey: 'email',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
      cell: ({ row }) => {
        const user = row.original;
        return <p className="whitespace-nowrap">{user?.email}</p>;
      },

      enableSorting: false
    },
    {
      accessorKey: 'phoneNumber',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Phone Number" />,
      cell: ({ row }) => {
        const user = row.original;
        return <p className="whitespace-nowrap">{user.phoneNumber ? user.phoneNumber : '-'}</p>;
      },
      enableSorting: false
    },
    {
      accessorKey: 'country',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Country" />,
      cell: ({ row }) => {
        const user = row.original;
        return <p className="whitespace-nowrap">{user.countryName ?? '-'}</p>;
      },
      enableSorting: false
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created At" />,
      cell: ({ row }) => {
        const user = row.original;
        return <p className="whitespace-nowrap">{dayjs(user.createdAt).format('MMM DD, YYYY')}</p>;
      },
      enableSorting: true
    },
    {
      accessorKey: 'approved',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Approved" />,
      cell: ({ row }) => {
        const user = row.original;
        return <FormCheckbox name="Approved" id="isApproved" checked={user.approved} onChange={() => toggleApproved(user)} />;
      },
      enableSorting: false
    },
    {
      accessorKey: 'apiClient',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Api Client" />,
      cell: ({ row }) => {
        const user = row.original;
        return (
          user.businessId && (
            <FormCheckbox name="Api Client" id="isApiClient" checked={user.isApiClient} onChange={() => toggleApiClient(user.businessId, user?.isApiClient)} />
          )
        );
      },
      enableSorting: false
    },
    {
      accessorKey: 'freeAccount',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Free Account" />,
      cell: ({ row }) => {
        const user = row.original;
        return (
          user.businessId && (
            <FormCheckbox
              name="Free Account"
              id="isFreeAccount"
              checked={user.isFreeAccount}
              onChange={() => toggleFreeAccount(user.businessId, user?.isFreeAccount)}
            />
          )
        );
      },
      enableSorting: false
    }
  ];

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters
    }
  });

  const toggleApproved = async (user) => {
    const updateUserUrl = updateUser(user?.id);
    try {
      await axiosPrivate.put(updateUserUrl, { approved: !user.approved });
      getUsers();
      toast.success(`Status of ${user.firstName} ${user.lastName} changed!`);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong...');
    }
  };

  const toggleApiClient = async (businessId, isApiClient) => {
    const updateBusinessUrl = updateBusiness(businessId);
    try {
      await axiosPrivate.put(updateBusinessUrl, { isApiClient: !isApiClient });
      getUsers();
      toast.success(`API Status changed!`);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong...');
    }
  };

  const toggleFreeAccount = async (businessId, isFreeAccount) => {
    const updateBusinessUrl = updateBusiness(businessId);
    try {
      await axiosPrivate.put(updateBusinessUrl, { isFreeAccount: !isFreeAccount });
      getUsers();
      toast.success(`Free account status changed!`);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong...');
    }
  };

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };

  return (
    <div>
      <div className="flex items-center justify-between py-2">
        <Heading text={`Total Users (${users.length})`} />
        <Input
          placeholder={`Search by user name...`}
          value={table.getColumn('firstName')?.getFilterValue() ?? ''}
          onChange={(event) => table.getColumn('firstName')?.setFilterValue(event.target.value)}
          className="max-w-sm"
        />
      </div>
      <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[800px]">
        <div className="relative rounded-3xl">
          {loading ? (
            <div className="flex items-center justify-center w-full my-20">
              <Loader />
            </div>
          ) : error ? (
            <div className="flex items-center justify-center w-full my-5">
              <p className="text-gray-500">{error}</p>
            </div>
          ) : (
            <Table>
              <TableHeader className="border-gray-300 border-b-[1.5px] bg-slate-50">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead className="whitespace-nowrap" key={header.id}>
                          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} className={`border-gray-300 border-b-[1.5px]`}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-left">
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
          {infoModal && user && <UserDetailModal user={user} isOpen={infoModal} title="User Details" close={toggleInfoModal} />}
        </div>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
};

export default UserTable;
