import dayjs from 'dayjs';

import { DataTableColumnHeader } from '../../../components/DataTable/DataTableColumnHeader.jsx';
import timeZone from '../../../helpers/guessTimezone';
import showPurchaseStatus from '../../../helpers/showPurchaseStatus';

export const columns = [
  {
    accessorKey: 'businessName',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Business Name" />,
    enableSorting: false
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created At" />,
    cell: ({ row }) => {
      const purchase = row.original;
      return <p className="whitespace-nowrap">{dayjs(purchase.createdAt).tz(timeZone).format('MMM DD, YYYY')}</p>;
    },
    enableSorting: true
  },
  {
    accessorKey: 'price',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Price" />,
    enableSorting: true
  },
  {
    accessorKey: 'currency',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Currency" />,
    enableSorting: true
  },
  {
    accessorKey: 'credits',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Credit Amount" />,
    enableSorting: true
  },
  {
    accessorKey: 'remainingCredits',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Remaining Credits" />,
    enableSorting: true
  },
  {
    accessorKey: 'isPaid',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => {
      const purchase = row.original;
      return <div className="whitespace-nowrap"> {showPurchaseStatus(purchase.isPaid)} </div>;
    },
    enableSorting: true
  }
  // {
  //   id: 'actions',
  //   cell: ({ row }) => {
  //     const delivery = row.original;

  //     return <DeliveriesDataTableActionsMenu data={delivery} />;
  //   }
  // }
];
