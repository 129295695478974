const showPurchaseStatus = (status) => {
  switch (status) {
    case 0:
      return <p className="px-2 py-1 font-bold text-center text-red-700 bg-red-200 rounded-full w-28">Failed</p>;
    case 1:
      return <p className="px-2 py-1 font-bold text-center text-green-700 bg-green-200 rounded-full w-28">Paid</p>;
    default:
      return <p className="px-2 py-1 font-bold text-center rounded-full w-28 bg-slate-100 text-stone-600">Unknown</p>;
  }
};

export default showPurchaseStatus;

<p className={`px-2 py-1 w-28 rounded-full text-center font-bold ${status === 1 ? 'text-green-700 bg-green-200' : 'text-orange-700 bg-orange-200'}`}>
  {status === 1 ? 'Paid' : 'Not Paid'}
</p>;
