import { Navigate, useLocation } from 'react-router-dom';

import Header from '../components/Header';
import Navigation from '../components/Navigation';
import useAuth from '../hooks/useAuth';

const AuthenticatedLayout = () => {
  const { auth, user } = useAuth();
  const location = useLocation();

  return (
    <>
      {user ? (
        <div className="mx-auto">
          <Header />
          {/* <Navbar /> */}
          <div className="max-w-full min-h-screen p-5 pt-5 bg-white">
            <Navigation />
            {/* <Outlet /> */}
          </div>
          {/* <Footer /> */}
        </div>
      ) : auth?.token ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default AuthenticatedLayout;
