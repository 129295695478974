import useAuth from './useAuth';
import { refresh as refreshRequest } from '../api/services/Auth';

const useRefreshToken = () => {
  const { setAuth, setUser } = useAuth();

  const refresh = async () => {
    const response = await refreshRequest();
    console.log(response);

    if (response) {
      setAuth((previous) => {
        return {
          ...previous,
          token: response.data.token
        };
      });
      setUser(response.data);

      return response.data.token;
    }
  };
  return refresh;
};

export default useRefreshToken;
