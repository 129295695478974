import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { getAllWithdrawals, markWithdrawalAsPaid } from '../../api/services/Withdrawals';
import Loader from '../../components/Loader';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Button from '../Button';
import ConfirmModal from '../Modals/ConfirmModal';
import WithdrawalPill from '../Pills/WithdrawalPill';

const WithdrawalsTable = () => {
  const axiosPrivate = useAxiosPrivate();

  const withdrawalsUrl = getAllWithdrawals();

  const [withdrawals, setWithdrawals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [withdrawal, setWithdrawal] = useState({});

  const headers = [
    { label: 'Created At', key: 1 },
    { label: 'Fleet Name', key: 3 },
    { label: 'Withdrawal amount', key: 4 },
    { label: 'Currency', key: 2 },
    { label: 'Status', key: 5 },
    { label: 'Action', key: 6 }
  ];

  useEffect(() => {
    getWithdrawals();
  }, []);

  const getWithdrawals = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(withdrawalsUrl);
      const withdrawals = response?.data;
      console.log(withdrawals);
      setWithdrawals(withdrawals);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError('Failed to load data, try entering page again.');
      toast.error('Something went wrong...');
      setLoading(false);
    }
  };

  const handlePaidWithdrawal = async () => {
    console.log('here', withdrawal);
    try {
      const paidWithdrawalUrl = markWithdrawalAsPaid(withdrawal?.id);
      const response = await axiosPrivate.put(paidWithdrawalUrl);
      console.log(response);
      toast.success(`Withdrawal marked as paid!`);
      getWithdrawals();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong...');
    }
    toggleConfirmationModal();
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[800px]">
      <div className="relative rounded-3xl">
        {loading ? (
          <div className="flex items-center justify-center w-full my-20">
            <Loader />
          </div>
        ) : error ? (
          <div className="flex items-center justify-center w-full my-5">
            <p className="text-gray-500">{error}</p>
          </div>
        ) : (
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase ">
              <tr className="sticky top-0 bg-gray-50">
                {headers.map((header) => (
                  <th key={header.key} scope="col" className="px-3 py-4 whitespace-nowrap">
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {withdrawals &&
                withdrawals.map((withdrawal) => (
                  <tr key={withdrawal.createdAt} className="bg-white border-b whitespace-nowrap">
                    <td className="px-3 py-4">{dayjs(withdrawal.createdAt).format('MMM DD, YYYY')}</td>
                    <td className="px-3 py-4">{withdrawal.fleetName}</td>
                    <td className="px-3 py-4">{withdrawal.withdrawableAmount.toFixed(2)}</td>
                    <td className="px-3 py-4">{withdrawal.fleetCurrency}</td>
                    {/* <td className="px-3 py-4">{withdrawal.status === 0 ? 'not paid' : 'paid'}</td> */}
                    <td className="px-4 py-4">
                      <WithdrawalPill status={withdrawal?.status} />
                    </td>
                    <td className="px-3 py-4">
                      {withdrawal.status === 0 && (
                        <Button
                          small={true}
                          label="View"
                          text="Mark as paid"
                          rounded={true}
                          onClick={() => {
                            toggleConfirmationModal(), setWithdrawal(withdrawal);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {confirmationModal && withdrawal && (
          <ConfirmModal cancel={toggleConfirmationModal} isOpen={confirmationModal} confirm={handlePaidWithdrawal} action={'mark this withdrawal as paid'} />
        )}
      </div>
    </div>
  );
};

export default WithdrawalsTable;
