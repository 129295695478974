import WithdrawalsTable from '../components/Withdrawals/WithdrawalsTable';

const Withdrawals = () => {
  return (
    <>
      <WithdrawalsTable />
    </>
  );
};

export default Withdrawals;
