import axios from '../axios';

const AUTH_URL = '/auth';

const login = async (email, password) => {
  try {
    const response = await axios.post(AUTH_URL + '/login', { email, password });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const refresh = async () => {
  try {
    const response = await axios.get(AUTH_URL + '/refresh');
    return response;
  } catch (error) {
    console.error(error);
  }
};

const logout = async () => {
  try {
    const response = await axios.get(AUTH_URL + '/logout');
    return response;
  } catch (error) {
    console.error(error);
  }
};

export { login, refresh, logout };
