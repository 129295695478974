const FormCheckbox = ({ name, id, checked, onChange }) => {
  return (
    <input
      name={name}
      type="checkbox"
      id={id}
      onChange={onChange}
      checked={checked}
      className="w-6 h-6 border-gray-300 rounded text-blue focus:ring-0 hover:cursor-pointer"
    />
  );
};

export default FormCheckbox;
