const DELIVERIES_URL = '/delivery';

export const getAllDeliveries = () => {
  const url = `${DELIVERIES_URL}`;
  return url;
};

export const cancelDelivery = (orderId) => {
  const url = `${DELIVERIES_URL}/cancel/${orderId}`;
  return url;
};
