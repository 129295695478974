import dayjs from 'dayjs';

import DeliveriesDataTableActionsMenu from './DeliveriesDataTableActionsMenu';
import { DataTableColumnHeader } from '../../../components/DataTable/DataTableColumnHeader';
import calculateDifference from '../../../helpers/calculateDifference';
import timeZone from '../../../helpers/guessTimezone';
import showDeliveryStatus from '../../../helpers/showDeliveryStatus';

export const columns = [
  {
    accessorKey: 'orderReference',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Order ID" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return <p className="font-bold text-gray-500 whitespace-nowrap">{delivery?.orderReference}</p>;
    },
    enableSorting: false
  },
  {
    accessorKey: 'externalOrderReference',
    header: ({ column }) => <DataTableColumnHeader column={column} title="External Order ID" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return <p className="text-orange-500 whitespace-nowrap">{delivery?.externalOrderReference ? `${delivery?.externalOrderReference}` : '---'}</p>;
    },
    enableSorting: false
  },
  {
    accessorKey: 'externalOrderPrice',
    header: ({ column }) => <DataTableColumnHeader column={column} title="External Order Price" />,
    cell: ({ row }) => {
      const delivery = row.original;
      const amount = parseFloat(row.getValue('externalOrderPrice'));
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'DKK'
      }).format(amount);

      return <div className="">{delivery?.externalOrderPrice ? formatted : '---'}</div>;
    },
    enableSorting: true
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created At" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return <p className="whitespace-nowrap">{dayjs(delivery.createdAt).tz(timeZone).format('MMM DD, YYYY')}</p>;
    },
    enableSorting: true
  },
  {
    accessorKey: 'fleetName',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Fleet Name" />,
    enableSorting: false
  },
  {
    accessorKey: 'businessName',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Business Name" />,
    enableSorting: false
  },
  {
    accessorKey: 'customerBusinessName',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Customer Company Name" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return delivery?.customerBusinessName ? delivery?.customerBusinessName : '---';
    },
    enableSorting: false
  },
  {
    accessorKey: 'totalFee',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Total Fee" />,
    enableSorting: true
  },
  {
    accessorKey: 'distance',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Distance (km)" />,
    enableSorting: true
  },
  {
    accessorKey: 'arrivedForPickupTime',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Arrived At" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return delivery?.arrivedForPickupTime ? <p className="whitespace-nowrap">{dayjs(delivery.arrivedForPickupTime).tz(timeZone).format('HH:mm')}</p> : '---';
    },
    enableSorting: false
  },
  {
    accessorKey: 'pickupTime',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Pickup Time" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return (
        <div className="whitespace-nowrap">
          {' '}
          {dayjs(delivery.pickupTime).tz(timeZone).format('HH:mm')} {calculateDifference(delivery.pickupTime, delivery.realPickupTime)}{' '}
        </div>
      );
    },
    enableSorting: true
  },
  {
    accessorKey: 'deliveryTime',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Delivery Time" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return (
        <div className="whitespace-nowrap">
          {' '}
          {dayjs(delivery.deliveryTime).tz(timeZone).format('HH:mm')} {calculateDifference(delivery.deliveryTime, delivery.realDeliveryTime)}{' '}
        </div>
      );
    },
    enableSorting: false
  },

  {
    accessorKey: 'deliveryCity',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Delivery City" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return <div className="whitespace-nowrap">{delivery.deliveryCity}</div>;
    },
    enableSorting: false
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => {
      const delivery = row.original;
      return <div className="whitespace-nowrap"> {showDeliveryStatus(delivery.status)} </div>;
    },
    enableSorting: true
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      const delivery = row.original;

      return <DeliveriesDataTableActionsMenu data={delivery} />;
    }
  }
];
