import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { getAllUsers } from '../api/services/User';
import Heading from '../components/Heading';
import UserTable from '../components/Users/UserTable';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const Users = () => {
  const axiosPrivate = useAxiosPrivate();

  const userUrl = getAllUsers();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(userUrl);
      const users = response?.data;
      console.log(users);
      setUsers(users);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError('Failed to load data, try entering page again.');
      toast.error('Something went wrong...');
      setLoading(false);
    }
  };

  return (
    <>
      {/* <DataTable columns={columns} entityName="Users" searchBy="user name" filterValue="firstName" data={users} loading={loading} /> */}
      <UserTable users={users} loading={loading} error={error} getUsers={getUsers} />
    </>
  );
};

export default Users;
