import axios from 'axios';
const BASE_URL = 'https://api.lastlap.delivery/api';
// const BASE_URL = 'http://localhost:8000/api';

axios.defaults.withCredentials = true;

export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});
