import { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import { getAllPurchases } from '../api/services/Purchases.js';
import { columns } from '../components/CreditPurchases/Table/columns';
import { DataTable } from '../components/DataTable/DataTable';
import DateSelectorFilter from '../components/Filters/DateSelectorFilter.jsx';
import PeriodFilter from '../components/Filters/PeriodFilter.jsx';
import useAxiosPrivate from '../hooks/useAxiosPrivate.js';

const CreditPurchases = () => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [period, setPeriod] = useState('This month');

  const axiosPrivate = useAxiosPrivate();

  const purchasesUrl = getAllPurchases();

  useEffect(() => {
    setError('');
    getPurchases();
  }, []);

  useEffect(() => {
    if (period === 'All time' || period === 'This month') {
      setStartDate('');
      setEndDate('');
      getPurchases();
    }
  }, [period]);

  const getPurchases = async () => {
    setLoading(true);

    let from = null;
    let to = null;

    if (period !== 'All time') {
      from = startDate && dayjs(startDate).format('YYYY-MM-DD HH:mm:ss');
      to = endDate && dayjs(endDate).format('YYYY-MM-DD 23:59:59');
    }

    if (period === 'This month') {
      from = dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss');
      to = dayjs().endOf('month').format('YYYY-MM-DD 23:59:59');
    }

    try {
      const response = await axiosPrivate.get(purchasesUrl, { params: { startDate: from, endDate: to } });

      console.log('purchases', response.data);
      if (response.data.length === 0) {
        setError('No ourchases have been made');
        setLoading(false);
      } else {
        setPurchases(response.data);
        setError('');
        setLoading(false);
      }
    } catch (error) {
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const totalCreditsBought = useMemo(() => {
    return purchases.reduce((acc, purchase) => {
      if (purchase.isPaid) {
        return acc + purchase.credits;
      }
      return acc;
    }, 0);
  }, [purchases]);

  const totalEarnings = useMemo(() => {
    return purchases.reduce((acc, purchase) => {
      if (purchase.isPaid) {
        return acc + purchase.price;
      }
      return acc;
    }, 0);
  }, [purchases]);

  // create a function to separate thousands and format them nicely
  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <>
      <div className="flex flex-col w-full m-auto">
        <div className="flex flex-col">
          <div className="text-left">
            <div className="flex flex-col mt-2 mb-2 space-y-2 md:space-y-0 md:flex-row md:justify-between">
              <PeriodFilter period={period} setPeriod={setPeriod} extended />
              {period !== 'All time' && period !== 'This month' && (
                <DateSelectorFilter
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  onClick={getPurchases}
                  withSearch={true}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <p className="text-lg">
            Total earnings: <span className="font-semibold text-blue">{formatNumber(totalEarnings)} DKK</span>
          </p>
          <p className="text-lg">
            Total credits bought: <span className="font-semibold text-blue">{formatNumber(totalCreditsBought)}</span>
          </p>
        </div>
        <div className="flex flex-col mt-2">
          <DataTable
            columns={columns}
            entityName="Credit Purchases"
            searchBy="Business Name"
            filterValue="businessName"
            data={purchases}
            loading={loading}
            error={error}
          />
        </div>
      </div>
    </>
  );
};

export default CreditPurchases;
