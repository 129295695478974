const USER_URL = '/user';

export const getAllUsers = () => {
  const url = `${USER_URL}/all`;
  return url;
};

export const updateUser = (id) => {
  const url = `${USER_URL}/${id}`;
  return url;
};
