import React from 'react';

import { useNavigate } from 'react-router-dom';

import DynamicIcon from './DynamicIcon';
import Logo from './Logo.jsx';
import logo from '../assets/logo.png';
import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';

const Header = () => {
  const navigate = useNavigate();
  const logout = useLogout();
  const { user } = useAuth();

  console.log(user);

  const signOut = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <>
      <div className="items-center hidden w-screen grid-cols-12 p-4 sm:grid">
        <div className="col-span-3 p-2 md:flex md:flex-row justify-self-start">
          <div className="mr-2">{user.email}</div>
        </div>
        <div className="flex w-full col-span-6 mx-auto place-content-center">
          <Logo />
        </div>
        <div
          className="col-span-3 p-2 border-2 rounded-lg cursor-pointer md:flex md:flex-row justify-self-end border-blue hover:bg-blue hover:text-white"
          onClick={signOut}>
          <div className="mr-2">Logout</div>
          <DynamicIcon icon="ArrowRightOnRectangleIcon" />
        </div>
      </div>

      {/* MOBILE VIEW */}
      <div className="flex flex-col items-center w-screen p-4 sm:hidden">
        <div className="flex w-full mx-auto place-content-center">
          <img src={logo} alt="logo" width="250" height="100" />
        </div>
        <div className="flex items-center justify-center w-full mt-2 space-x-2">
          <div className="p-2">
            <div className="mr-2">{user.email}</div>
          </div>
          <div className="flex p-2 border-2 rounded-lg cursor-pointer border-blue hover:bg-blue hover:text-white" onClick={signOut}>
            <div className="mr-2">Logout</div>
            <DynamicIcon icon="ArrowRightOnRectangleIcon" />
          </div>
        </div>
      </div>
    </>

    // <div className="grid items-center w-screen grid-cols-12 p-4">
    //   <div className="hidden col-span-3 p-2 rounded-lg md:flex md:flex-row justify-self-start ">
    //     <div className="mr-2">{user.email}</div>
    //   </div>
    //   <div className="flex w-full col-span-6 mx-auto place-content-center">
    //     <img src={logo} alt="logo" width="250" height="100" />
    //   </div>
    //   <div
    //     className="hidden col-span-3 p-2 border-2 rounded-lg cursor-pointer md:flex md:flex-row justify-self-end border-blue hover:bg-blue hover:text-white"
    //     onClick={signOut}>
    //     <div className="mr-2">Logout</div>
    //     <DynamicIcon icon="ArrowRightOnRectangleIcon" />
    //   </div>

    //   {/* MOBILE VIEW */}
    //   <div className="flex flex-col items-center w-screen p-4 sm:hidden">
    //     <div className="flex w-full mx-auto place-content-center">
    //       <img src={logo} alt="logo" width="250" height="100" />
    //     </div>
    //     <div className="flex items-center justify-center w-full mt-2 space-x-2">
    //       <div className="p-2 border-2 rounded-lg cursor-pointer border-blue hover:bg-blue hover:text-white">
    //         <div className="mr-2">{user.email}</div>
    //       </div>
    //       <div className="flex p-2 border-2 rounded-lg cursor-pointer border-blue hover:bg-blue hover:text-white" onClick={signOut}>
    //         <div className="mr-2">Logout</div>
    //         <DynamicIcon icon="ArrowRightOnRectangleIcon" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Header;
