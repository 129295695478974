import { useState } from 'react';

import { MoreHorizontal } from 'lucide-react';
import { toast } from 'react-toastify';

import { axiosPrivate } from '../../../api/axios';
import { cancelDelivery } from '../../../api/services/Deliveries';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '../../ui/alert-dialog';
import { Button } from '../../ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '../../ui/dropdown-menu';

const DataTableActionsMenu = ({ data }) => {
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const cancelOrderUrl = cancelDelivery(data?.id);

  const confirmOrderCancellation = async () => {
    try {
      const response = await axiosPrivate.put(cancelOrderUrl);
      console.log(response.data);
      toast.success(`Delivery #${data.orderReference} has been cancelled`);
      setShowCancelDialog(false);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong...');
      setShowCancelDialog(false);
    }
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="w-8 h-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="w-4 h-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => navigator.clipboard.writeText(data.orderReference)} className="hover:cursor-pointer">
            Copy order ID
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {/* <DropdownMenuItem>View delivery</DropdownMenuItem> */}
          <DropdownMenuItem onSelect={() => setShowCancelDialog(true)} className="text-red-600 hover:cursor-pointer">
            Cancel order
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <AlertDialog open={showCancelDialog} onOpenChange={setShowCancelDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This order will be cancelled and used credits (if any) will be returned to the business.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button
              variant="destructive"
              onClick={() => {
                confirmOrderCancellation();
              }}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DataTableActionsMenu;
