const WITHDRAWAL_URL = '/withdrawals';

export const getAllWithdrawals = () => {
  const url = `${WITHDRAWAL_URL}/all`;
  return url;
};

export const markWithdrawalAsPaid = (id) => {
  const url = `${WITHDRAWAL_URL}/paid/${id}`;
  return url;
};
