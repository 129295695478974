import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import dayjs from 'dayjs';

const UserDetailModal = ({ title, isOpen, close, user }) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-xl p-6 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title as="h3" className="pb-4 text-xl font-bold leading-6 border-b-2 border-gray-100 text-blue">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2 text-left">
                    <div className="flex text-lg">
                      <p className="">Created at: </p>
                      <p className="ml-2 font-bold">{dayjs(user.createdAt).format('MMMM DD, YYYY')}</p>
                    </div>
                    <div className="flex text-lg">
                      <p className="">Account type: </p>
                      <p className="ml-2 font-bold">{user?.type}</p>
                    </div>

                    <div className="my-2 border-b border-gray-200"></div>

                    <div className="flex text-lg">
                      <p className="whitespace-nowrap">User Name: </p>
                      <p className="ml-2 font-bold">
                        {user?.firstName} {user?.lastName}
                      </p>
                    </div>

                    <div className="flex text-lg">
                      <p className="whitespace-nowrap">{user.type === 'fleet' ? 'Fleet Name:' : 'Business name:'} </p>
                      <p className="ml-2 font-bold">{user.type === 'fleet' ? `${user.fleetName}` : `${user.businessName}`}</p>
                    </div>

                    <div className="flex text-lg">
                      <p className="">Email: </p>
                      <p className="ml-2 font-bold">{user?.email}</p>
                    </div>
                    <div className="flex text-lg">
                      <p className="">Phone number: </p>
                      <p className="ml-2 font-bold">{user?.phoneNumber}</p>
                    </div>

                    <div className="flex text-lg">
                      <p className="whitespace-nowrap">Address: </p>
                      <p className="ml-2 font-bold">
                        {user.type === 'fleet' ? `${user.fleetAddress}` : `${user.businessAddress}`}, {user?.postCode}
                      </p>
                    </div>

                    {user.cvrNumber && (
                      <div className="flex text-lg">
                        <p className="">Cvr number: </p>
                        <p className="ml-2 font-bold">{user.cvrNumber}</p>
                      </div>
                    )}
                  </div>

                  <div className="my-2 border-b border-gray-200"></div>

                  <div className="mt-4 space-x-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium bg-blue-100 border rounded-md border-blue text-blue hover:bg-blue hover:text-white focus:outline-none"
                      onClick={close}>
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UserDetailModal;
